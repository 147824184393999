@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

/**************************[ BASE ]**************************/
body {
	font-family: 'Open Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
	color: #2A2A6C;
	background-color: #fff;
}

a, body, i, p, span, u {
	font-size: 16px;
	line-height: 24px;
}
h1 {
	font-size:36px;
	letter-spacing:1px;
	text-transform:uppercase;
	font-family: 'Open Sans', sans-serif;
	font-weight:bold;
	color:#2a2a6c;
    line-height:40px;
}
h2 {
	font-size:30px;
	font-weight:bold;
	letter-spacing:0;
	text-transform: none !important;
	line-height:38px;
	font-family: 'Open Sans', sans-serif;
}
h3 {
	font-size:20px;
	font-weight:bold;
	line-height:inherit;
	font-family: 'Open Sans', sans-serif;
}
h4 {
	font-weight:bold;
	font-size:16px;
	font-family: 'Open Sans', sans-serif;
}
h6 {
	font-size:24px;
	letter-spacing:3px;
	text-transform:uppercase;
	font-family: 'Open Sans', sans-serif;
}
.wrapper {
	border-top:none;
}

#opc-login h3, #subscribe-form label, .accordion .heading, .block .block-title, .box-account .box-head h2, .caption .heading, .feature .heading, .footer .heading, .gen-tabs .tabs a, .main-font, .mobnav-trigger, .nav-mobile li.level0>a, .nav-mobile li.level1>a, .nav-mobile li.level2>a, .nav-regular .nav-submenu--mega>li>a, .nav-regular li.level0>a, .order-items .order-comments h2, .order-items h2.sub-title, .order-items h2.table-caption, .product-view .box-reviews dt .heading, .products-grid .product-name, .products-list .product-name, .section-title, body, h1, h2, h3, h3.product-name, h4, h5
{
	font-family: 'Open Sans', sans-serif !important;
}

.gen-tabs .tabs a.current {
	color:#2a2a6c;
}

/**************************[ HEADER ]**************************/
.header a, .header p, .header span {
	font-size: 16px;
	color: #2a2a6c;
	font-family: 'Open Sans', sans-serif;
}
.header .dropdown, .header-top {
	line-height: normal;
}

/* Top bar */
.header-container {
	background-color: #fff;
	padding-bottom: 0;
}
.header-top-container {
	background-color:#fff;
	box-shadow:none;
}

.header-top-container .header-top {
	line-height: normal;
}
.welcome-msg *, .userCode, .userName {
	color: #2a2a6c !important;
}

/* Logo */
.logo img {
	max-width: 250px;
}

/* Search bar */
.search-wrapper-centered .form-search {
	position: relative;
}
.search-wrapper-centered .form-search .input-text {
	width: 100%;
	background-color: #fff;
	border-color: #2a2a6c;
	color: #000;
	font-family: 'Open Sans', sans-serif;
	display: block;
}
.search-wrapper-centered .form-search .button-search {
	position: absolute;
	top: 0;
	right: 0;
}
.search-wrapper-centered .form-search .button-search .fa {
	font-size: 16px;
	color: #2a2a6c;
	padding: 10px;
}
.search-wrapper-centered .form-search-globalfilter {
	position: relative;
}
.search-wrapper-centered .form-search-globalfilter label[for="ignore-filter-ignore"] {
	font-size: 12px;
}

/* Cart Widget */
.user-menu, .user-menu a {
	color: #2a2a6c;
}
.dropdown-toggle *, .header span.cart-total, .header span {
	color: #2a2a6c;
}
.dropdown-toggle .value {
	color: #2a2a6c;
}
.dropdown.open .dropdown-toggle .value {
	color: #2a2a6c;
}
.header .open > .dropdown-toggle.cover > div {
	background-color: transparent;
}
.feature-icon-hover .caret {
	padding-top: 10px;
	font-weight: 700;
	border-top-color: #2a2a6c;
	border-width: 6px 5px 0;
}
.dropdown-toggle .icon.close-to-text {
	display: none;
}
.dropdown-toggle .hide-below-1280,
.dropdown-toggle .amount {
	padding: 0.5em 5px;
	font-size: 14px;
}
.empty span.price {
	margin-top: 5px;
	padding-right: 0.5em;
}
.header .fa.fa-shopping-cart {
	position: relative;
	top: 8px;
	left: -15px;
	font-size: 18px;
}
#root-wrapper span.fa {
	font-size: 18px;
}

/* Menu */
.custom-freeshipping-note a, .links > li > a, .nav-regular li.level0 > a > span {
	color: #2a2a6c;
	font-size: 16px;
	font-family: 'Open Sans', sans-serif;
}
.links > li > a:hover {
	background-color: #fff;;
	color: #2a2a6c !important;
}
#nav {
	margin-top: 0;
	margin-bottom: 0;
}
#nav, .mobnav-trigger {
	background-color: #fff;
	border-bottom: none;
}
.nav-regular .nav-item.level0:hover > a,
.nav-regular .nav-item.level0:hover > a > span,
.nav-mobile .nav-item.level0 > a:hover {
	background-color: transparent;
	color: #2a2a6c;
}
.nav-regular li.level0 > .nav-panel--dropdown, .nav-regular .mega > .nav-panel--dropdown, .nav-regular .classic > .nav-panel--dropdown {
	border-top: 5px solid #2a2a6c;
}
#nav .nav-panel--dropdown a {
	color: #2a2a6c;
	font-size: 16px;
}
#nav .nav-panel--dropdown a > span {
	font-size: 16px;
}
#nav .nav-panel--dropdown a:hover {
	color: #2a2a6c;
}
.mobnav-trigger.active {
	background-color: #2a2a6c;
}
.cms-index-index .nav-regular .nav-item--home > a, .nav-regular .nav-item.level0.active > a, .nav-mobile .nav-item.level0.current > a {
	background-color: transparent;
	color: #2a2a6c;
}
#nav .nav-item--home .fa-home {
	color: #2a2a6c !important;
	padding: 0.3rem;
}
#root-wrapper a:hover span.fa:before {
	color: #2a2a6c;
}
#nav .nav-item.level0.parent:hover > a .caret {
	border-top-color: #2a2a6c;
}
#nav .nav-panel--dropdown a:hover .caret {
	border-left-color: #2a2a6c;
	border-right-color: #2a2a6c;
}
#mini-cart .actions button {
	width: 100%;
}
#mini-cart .actions button.button span {
	background-color: transparent !important;
	width: 100%;
}
#mini-cart .actions button.button span span {
	background-color: #2a2a6c !important;
	padding: 0;
	border-radius: 5px;
	max-height: 40px;
	font-size: 16px;
	margin-top: 10px;
}
#mini-cart .actions button.button span span:hover {
	background-color: #2a2a6c !important;
}
.dropdown-menu > li * {
	float: none;
	display: inline-block;
}
.dropdown-menu > li > a {
	display: inline-block;
}

/**************************[ BODY ]**************************/
.breadcrumbs a, .breadcrumbs li {
	font-size: 14px;
}
.breadcrumbs li span {
	margin: 10px 4px 0;
	font-size: 14px;
	letter-spacing: 2px;
	text-transform: uppercase;
}
.breadcrumbs li span.breadcrumb-separator {
	background: url(../images/img/pix.png) 0 -129px no-repeat;
	width: 7px;
	height: 5px;
	display: block;
	float: left;
	text-indent: -9999px;
	overflow: hidden;
	margin: 12px 4px 0;
}
h1, h1.banner-h1, .banner-h1 a {
	font-size: 36px;
	color: #2a2a6c;
	letter-spacing: 1px;
	text-transform: uppercase;
}
.pager .amount, .sorter .amount {
	line-height: 36px;
}
.toolbar label, .toolbar p, .toolbar span {
	font-size: 14px;
}
.products-grid.single-line-name .product-name {
	overflow: visible;
	text-overflow: initial;
	word-wrap: break-word;
	white-space: normal;
}
.products-grid.centered .item {
	font-size: 14px;
	line-height: 20px;
}
.products-grid .product-name, h3.product-name a {
	font-size: 16px;
}
.bootstrap-styles h2 {
	letter-spacing: 0;
}
.product-view .btn-cart span, button.button span {
	color: #fff;
}
.add-cart-wrapper button span span {
	width: 140px;
	max-width: 140px;
}
.product-view .btn-cart span, button.button span {
	margin: 0;
	background-color: #2a2a6c;
	border-radius: 5px;
	padding: 0;
}
button, .button, .button a, button a {
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
}
button.button span {
	font-size: 16px;
}
button.button span span {
	padding: 0 24px;
    height: 40px;
    line-height: 40px;
}
.add-cart-wrapper button:last-child {
	margin-top: 10px;
}
.btn-filled:hover, .button:hover span {
	background-color: #2a2a6c !important;
}
.products-grid.centered .item {
	line-height: 20px;
}
.item .price-box .price, .item .price-box .price span, .xm-owl-carousel .price-box .price, .xm-owl-carousel .price-box .price span {
	font-size: 14px;
	color: #2a2a6c;
}
.price-box .minimal-price .price, .price-box .regular-price .price, .price-box-bundle .full-product-price .price {
	color: #2a2a6c;
}
.main .col-main, .main .sidebar {
	margin-top: 1%;
	margin-bottom: 1%;
}
.account-login {
	margin: 15px 0 0 0;
	background-color: #fff;
}
.block-title span {
	font-size: 20px;
}
.form-list label {
	font-weight: unset;
}
div[class^='account'] p, div[class^='account'] h2 {
	text-align: left;
}

div[class^='account'] .form-list input.input-text {
	float: left;
}
.category-title, .my-account .page-title {
	border-bottom: none;
}
.pager .pages li a {
	background-color: #AEAEB2;
    color:#2a2a6c;
}
.pager .pages li.current,
.pager .pages li a {
	width: 30px;
	height: 30px;
	line-height: 30px;
}

/**************************[ Footer ]**************************/
.footer-container {
	background-color: #fff;
}
.footer-container, .footer-container2, .footer-primary.footer.container, .footer-top
{
	background-color: #fff;
}
.footer-primary.footer.container {
    background-color: #2a2a6c;
}
.footer-top-container {
	padding-top: 10px;
}
.footer-primary-container {
	background-color: transparent;
	padding-top: 10px;
	padding-bottom: 10px;
}
.footer-bottom-container.section-container {
	background-color: #fff;
	color: #2a2a6c;
}


/************************** MICS **************************/

#footer {
     border-top:1px solid #143D8D;
}
.footer-bottom-container .item-left {
     float:none;
     text-align:center;
}
.footer-copyright {
     display:block !important;
     margin-bottom:0;
     color:#2a2a6c;
}
@media only screen and (min-width:768px) {
     .links-column .links-footer {
         display:flex;
         justify-content:space-between;
    }
}
.links-column .links-footer a {
     color:#fff;
}
.links-column .links-footer a:hover {
     border-bottom:1px solid #fff;
}
@media only screen and (max-width:767px) {
     #footer .logo-column, #footer .links-footer {
         text-align:center;
    }
     #footer .links-footer > div{
         margin:10px 0;
    }
}

/**/
.header-top-container .module-header-multi{
     margin-left:0;
     margin-right:0;
     width:100%;
}
.header-top-container .module-header-multi .item-welcome-msg{
     margin-left:0;
}
.top-header-container .module-header-multi span, .top-header-container .module-header-multi a{
     height:32px;
     line-height:32px;
}
.top-header-container .module-header-multi p{
     margin-bottom:0 !important;
     height:32px;
     line-height:32px;
}
.top-header-container .item.item-user-links {
     margin-right:0;
}
.top-header-container .item.item-user-links ul.links > li:last-child a{
     padding-right:0;
}
.desktop-top-header {
     padding:20px 0;
     border-bottom:1px solid #2a2a6c;
}
.std i {
     font-style:normal;
}
.desktop-top-header .logo-column, .desktop-top-header .cart-column {
     margin:auto 0;
}
.desktop-top-header .cart-column {
     width:auto;
     margin-top:7px;
}
@media only screen and (max-width:1199px) {
     .userSwitch a {
         text-indent:-9999px;
         position:absolute;
    }
     .userSwitch a::after {
         content:"\f0c0";
    }
     .userSwitch a::after {
         font-family:fontAwesome;
         font-size:14px;
         text-indent:0;
         float:left;
    }
}
.desktop-category-column {
    border-bottom:1px solid #2a2a6c;
}
.desktop-category-column .module-category-menu .nav-item.nav-item--home {
     display:none;
}
.desktop-category-column .nav-regular li.level0>a>span {
     font-weight:600;
     text-transform:Capitalize;
     font-size:18px;
}
@media only screen and (min-width:1600px) {
     .desktop-category-column .nav-regular li.level0>a>span {
         font-size:20px;
    }
}
.desktop-category-column .nav-regular .nav-panel li.classic {
     margin-bottom:5px;
}
.desktop-category-column ul#nav {
     display:flex;
     justify-content:space-between;
     margin-left:0;
     margin-right:0; 
     width:100%;
}
.desktop-category-column ul#nav > li.nav-1 > a {
     padding-left:0;
}
@media only screen and (max-width:959px) {
     .hide-below-960 {
         display:none !important;
    }
     #mini-cart .hide-below-960 {
         display:inline-block !important;
    }
}
@media only screen and (min-width:960px) {
     .hide-above-960 {
         display:none !important;
    }
}
@media only screen and (min-width:768px) {
     .hide-above-768 {
         display:none !important;
    }
}
@media only screen and (max-width:767px) {
     .hide-below-768 {
         display:none !important;
    }
}
.module-search .form-search input::placeholder {
     color:#2a2a6c !important;
}
.module-search .form-search .button-search .fa {
     color:#2a2a6c !important;
     font-weight:600;
}
.desktop-top-header .module-search .form-search .input-text {
     border-radius:0;
     font-weight:400;
     font-size:16px;
     border:none;
     border-bottom:1px solid #2a2a6c;
}
.cart-column .fa.fa-shopping-cart {
     float:right;
     font-size:22px !important;
     margin:0 7px;
     margin-right:0;
}
.cart-column .feature-icon-hover {
     padding-right:0 !important;
}
.feature-icon-hover .caret {
     border-top-color:#2A2A6C;
     opacity:1;
}
.cart-total {
     display:inline-block;
     border-radius:50%;
     color:#fff !important;
     background-color:#2a2a6c;
     font-weight:700;
     font-size:12px !important;
     height:20px;
     width:20px;
     line-height:20px;
     text-align:center;
     position:relative;
     top:-8px;
}
#mini-cart .feature-icon-hover > a{
     display:inline-flex;
}
#mini-cart .feature-icon-hover > a > span {
     padding:0 !important;
}
#mini-cart .feature-icon-hover > a > span, #mini-cart .feature-icon-hover > a > .empty {
     order:1;
}
#mini-cart .feature-icon-hover > a > .empty span.price {
     margin-top:0;
}
#inline-search-results {
     right:0;
     left:initial;
}
@media only screen and (min-width:960px) {
     #inline-search-results {
         width:900px;
         right:0;
         left:initial;
    }
     #inline-search-results .grid12-8.results-right {
         width:68.5%;
    }
     #inline-search-results li.grow {
         width:45%;
    }
}
@media only screen and (max-width:767px) {
     #inline-search-results {
         width:96%;
         left:0 !important;
         margin:auto;
         max-height:700px;
         overflow:auto;
    }
     #inline-search-results li.grow {
         width:45% !important;
    }
}
@media only screen and (min-width:768px) and (max-width:959px) {
     #inline-search-results {
         width:500px;
         right:0 !important;
         margin:auto;
         max-height:700px;
         overflow:auto;
    }
     #inline-search-results li.grow {
         width:45% !important;
    }
}
@media only screen and (max-width:959px) {
     .desktop-top-header, .cart-column, .cart-column .grid-column-wrapper, .cart-column .module-header-multi {
         overflow:unset;
    }
     .fa.fa-bars.userCode {
         margin-right:0;
    }
     .cart-column {
         padding:5px 0 !important;
         margin-top:0;
    }
     .desktop-top-header {
         padding:10px 0 !important;
    }
}
@media only screen and (max-width:767px) {
     .mobile-header {
         border-bottom:1px solid #2a2a6c;
    }
     .top-header-container, .mobile-header {
         margin-bottom:5px !important;
    }
     .mobile-header .grid-container {
         display:flex;
         overflow:hidden !important;
    }
     .mobile-header .grid-container > div{
         margin:auto;
    }
     .header .userCode, .header .userName, .header .userSwitch {
         font-size:14px;
    }
     .mobile-header .grid-full.module-header-multi {
         display:flex;
         justify-content:space-evenly;
    }
     .top-header-container .userSwitch a:after, .mobile-header .fa.fa-bars, .mobile-header .fa.fa-vcard:before, .mobile-header .fa.fa-shopping-cart, .mobile-header .item-search:not(.fly-out) .fa.fa-search {
         color:#143d8d !important;
         font-size:20px !important;
    }
     .mobile-header .header .logo-wrapper {
         text-align:left !important;
         margin:0 !important;
    }
     .mobile-header .item-search {
         margin-left:0 !important;
    }
     .mobile-header .fa-shopping-cart {
         left:0 !important;
         top:4px !important;
    }
     .mobile-header #mini-cart .empty {
         display:none;
    }
     .mobile-header .mobile-logo-img img {
         max-width:22px;
    }
     .mobile-header .mobile-logo-img {
         width:auto !important;
    }
     #mini-cart .feature-icon-hover > a {
         height:40px;
    }
     #mini-cart .feature-icon-hover > a > span, #mini-cart .feature-icon-hover > a > .hide-below-960 {
         margin:auto;
    }
     /*Search on mobile*/
     .mobile-header div.form-search {
         margin:0 auto;
    }
     .mobile-header .module-search .form-search .input-text {
         font-size:16px !important;
    }
     /*Search*/
     .mobile-header .module-search .form-search .input-text {
         display:none;
    }
     .mobile-header .module-search .button.button-search {
         position:unset!important;
    }
     .mobile-header .item-search.fly-out {
         position:fixed;
         display:block!important;
         top:0;
         right:0;
         left:0;
         bottom:0;
         z-index:9999;
         width:100%!important;
         background-color:#fff;
         margin-left:0;
    }
     .mobile-header .item-search.fly-out .form-search .button {
         right:10px;
         position:absolute!important;
         margin:0;
         padding:0;
         font-size:inherit;
    }
     .mobile-header .item-search.fly-out .form-search .button-search {
         right:45px;
    }
     .mobile-header .form-search .button-close .fa {
         font-size:16px;
         color:#2a2a6c;
         padding:10px;
         font-style:normal;
    }
     .cart-total {
         background-color:#143d8d;
    }
}
.isPublic .top-header-container {
     display:none;
}
.page-content ul, #product-tabs .std ul, .product-shop.module-product-details ul:not(.add-to-links) {
     list-style:disc;
     margin-left:25px;
}
#header {
     position:sticky;
     top:0;
     z-index:50;
}
@media print {
     .ord-details .grid-column-wrapper {
         width:98%;
    }
     .module-trans-details-listing {
         max-width:95%;
    }
     .module-order-details-listing {
         max-width:95%;
    }
     @page {
         size:landscape 
    }
}
.isPublic .hide-for-B2C {
     display:none;
}
body:not(.isPublic) .hide-for-B2B {
     display:none;
}
input.input-text[disabled=disabled], input[disabled=disabled], input.input-text[disabled=disabled]::placeholder, input[disabled=disabled]::placeholder {
     color:#2A2A6C !important;
     -webkit-text-fill-color:rgba(0, 0, 0, 0.8);
     -webkit-opacity:0.8;
}
input.input-text, select, textarea {
     color:#2A2A6C;
     font-size:18px;
     border-color:#2a2a6c;
     border-radius:5px;
}
/**/
body.cart .data-table:not(.cart-table) .col-img img {
     width:60%;
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .opc-main-container.onepage-checkout-main-container, .in-sidebar.opc-progress-container {
         width:98%;
    }
}
@media only screen and (max-width:767px) {
     body.cart .data-table tfoot td {
         display:inline-block !important;
         width:30% !important;
         min-width:100px;
         max-width:140px;
         margin-right:10px;
         font-weight:700;
    }
}
.cart-action-buttons button {
     margin:20px 10px;
}
@media only screen and (max-width:767px) {
     #shopping-cart-table {
         overflow:hidden;
    }
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .cart-action-col {
         overflow:hidden;
    }
}
#shopping-cart-table h2.product-name .item-code {
     display:none;
}
@media only screen and (min-width:1200px){
     .cart-summary-col {
         width:30%;
    }
     .update-empty-cart {
         width:66%;
    }
}
/**/
.account-view .sidebar.my-account-sidebar.mmenu-hide {
     margin-bottom:70px;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu {
     display:flex;
     float:left;
     width:100%;
     background-color:#fff;
     padding-left:0 !important;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li {
     margin:0 auto;
     list-style:none;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li:hover {
     background-color:#2a2a6c;
     border-radius:0;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li:hover a{
     color:#fff;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li > a{
     font-weight:bold;
     padding:0 12px;
     line-height:40px;
}
.account-view .block-content.mm-menu ul.xm-mega-menu > li {
     width:100%;
}
.account-view .user-grid-content .page-title {
     overflow:hidden;
}
.account-view .xm-grid-account .grid-container-wrapper {
     overflow-x:hidden;
}
.progress, div[class^=strength_meter_passwd] {
     top:25px !important;
}
#order-table, #transhistory-table, #address-book-table, #shipment-table{
     margin-top:20px;
}
.module-order-details-actions p.back-link .fa-angle-double-left:before {
     content:"";
}
@media only screen and (min-width:960px) {
     .order-info-box.module-order-details-billing, .order-info-box.module-order-details-shipping, .order-info-box.module-shipment-details-billing, .order-info-box.module-trans-details-shipping, .col-1.addresses-primary .item, .col-2.addresses-primary .item{
         min-height:250px;
    }
     .module-order-details-misc > div {
         padding-left:10px;
    }
}
.buttons-set.module-order-details-actions {
     text-align:end;
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .billing-module, .shipping-module, .col-1.addresses-primary, .col-2.addresses-primary {
         width:99%;
    }
     .col-2.addresses-primary {
         float:left;
    }
}
@media only screen and (max-width:959px) {
     .ReactTable .rt-thead.-filters input{
         max-width:80px;
    }
     .ReactTable .rt-thead.-filters select{
         width:80%;
         height:26px;
    }
     .ReactTable a, .ReactTable div, .ReactTable span{
         font-size:11px !important;
    }
     .module-account-transhistory-content-placeholder {
         margin-top:20px;
    }
     .module-order-details-listing, .ord-comment {
         overflow-x:hidden;
    }
}
#openinvc-href span span{
     padding:10px;
}
.page-title.module-trans-details-status h1 span {
     font-size:32px;
}
.module-trans-details-actions .btn.btn-outline-secondary.btn-pay-invocie, #openinvc-href span span, a.button span span {
     padding:10px;
     background-color:#2a2a6c;
     border-radius:5px;
     cursor:pointer;
     color:#fff;
     border:1px solid #2a2a6c;
}
.module-trans-details-actions .btn.btn-outline-secondary.btn-pay-invocie:hover, a.button:hover span span {
     background-color:#2a2a6c ;
     color:#fff !important;
}
.module-trans-details-actions input.form-control {
     height:30px;
     width:70px;
}
@media only screen and (max-width:767px) {
     button[title='Change Email'] {
         margin-bottom:50px !important;
    }
}
.login-title h1 {
     text-align:center;
}
@media only screen and (min-width:960px) {
     .login-section .grid-container {
         display:flex;
         justify-content:center 
    }
}
@media only screen and (min-width:1399px) {
     .login-section .grid-column-wrapper {
         width:32%;
    }
}
@media only screen and (max-width:767px) {
     .login-section .grid-column-wrapper {
         margin-top:5%;
    }
}
/*Pay Open Invoice*/
.po-invc .pager .amount {
     padding-top:0;
}
.po-invc #form_filter_user_data input, .po-invc #form_filter_user_data button {
     margin-left:10px;
}
.po-invc .buttons-set p.back-link {
     display:none;
}
@media only screen and (max-width:767px) {
     .po-invc #form_filter_user_data {
         display:flex;
         margin-bottom:20px;
    }
     .po-invc #form_filter_user_data > * {
         margin:auto;
    }
     .po-invc .filter_order_search_label {
         line-height:12px;
    }
     .po-invc .my-account .pager p.amount strong, .po-invc .filter_order_search_label, .po-invc .my-account > p, .po-invc button.button span, .po-invc .pages strong, .po-invc .pages li, .po-invc .pages li a{
         font-size:12px !important;
         font-weight:400 !important;
    }
     .po-invc .pages ol {
         padding-left:0;
         margin-top:5px;
    }
     .po-invc .my-account .pager p.amount {
         padding-left:5px;
         float:left;
    }
}
@media only screen and (min-width:1200px) {
     .login-section .nested-container {
         width:80% !important;
    }
}
.login-section .buttons-set{
     margin-left:0;
}
.login-section .buttons-set button{
     float:left;
}
.module-login-form.login-form-wrapper ul.form-list li:nth-child(1){
     margin-bottom:10px;
}
.module-login-form.login-form-wrapper ul.form-list li:nth-child(2){
     margin-bottom:20px;
}
.module-login-form.login-form-wrapper div.buttons-set{
     display:flex;
     flex-direction:column-reverse;
     justify-content:start;
     margin-left:0;
}
.module-login-form.login-form-wrapper p.required {
     display:none;
}
.module-login-form.login-form-wrapper div.buttons-set > a{
     line-height:50px;
}

/**/
.sidebar-wrapper.module-category-filters .block-title span, .sidebar-wrapper.module-category-filters li a{
     text-transform:Capitalize;
     color:#2a2a6c;
}
.sidebar-wrapper.module-category-filters .block-title, .accordion-style1 li a {
     border:none;
}
.product-listing {
     margin-top:0 !important;
}
.product-listing .add-cart-button span span, .product-listing .button.more-info span span{
     padding:0px !important;
}
.product-listing .page-title h1 {
     padding-top:0;
     padding-bottom:20px;
}
.product-search-container, .breadcrumbs-col .grid-column-wrapper.first, .breadcrumbs-col .module-category-misc > div, #breadcrumbs li.home span, .breadcrumbs-col .nested-container {
     margin-left:0 !important;
}
#breadcrumbs li.last-child > span {
     vertical-align:super;
}
.breadcrumbs li span {
     text-transform:Capitalize;
     color:#2a2a6c;
}
.breadcrumb-separator {
     background:none !important;
     text-indent:0 !important;
     overflow:unset !important;
     margin:0 !important;
     font-size:16px !important;
     width:auto !important;
     margin-top:2px !important;
}
.pager {
     border:none;
     margin:0;
     padding:0;
}
.pager li a, .pager strong{
     font-size:14px;
     color:#2a2a6c;
}
.pager .pages li.current {
     background-color:#fff;
     color:#2a2a6c;
}
.pager .pages li a:hover {
     background-color:#fff;
     color:#2a2a6c !important;
}
.toolbar-section .module-category-misc > div:nth-child(1) {
     margin-top:5px;
}
.category-products-grid .item {
     border:none;
     margin-bottom:50px;
     padding-bottom:95px !important;
}
.item .module-category-product-listing h2.product-name {
     margin-bottom:15px;
}
.item .module-category-product-listing h2.product-name a {
     color:#2A2A6C;
}
.item .module-category-product-listing .product-image-wrapper.product-description-wrapper {
     margin-bottom:10px;
}
.item .module-category-product-listing .product-description-wrapper .product-shop-inner {
     min-height:60px;
}
.item .module-category-product-listing .price-box {
     margin-top:15px;
}
.item .module-category-product-listing .price-box span{
     font-weight:600;
}
.item .module-category-product-listing .price-box .regular-price span {
     color:#2A2A6C;
}

.item .module-category-product-listing .add-cart-wrapper input.qty {
     height:40px;
     border:1px solid #2a2a6c;
}
.item .module-category-product-listing .item-code-label {
     display:none;
}
@media only screen and (max-width:959px) {
     .item .module-category-product-listing .grid12-12, .breadcrumbs-col .grid-column-wrapper {
         overflow-y:hidden;
    }
     .mobile-filters {
         vertical-align:super !important;
    }
     .mobile-filters .fa-sliders:before {
         font-size:22px;
         font-weight:700;
         color:#2a2a6c;
    }
}

.category-products-grid .item:hover {
     z-index:10;
     box-shadow:0 0 20px rgba(0,0,0,.2);
     border-color:transparent;
     transition:box-shadow .4s ease-out;
     -moz-transition:box-shadow .4s ease-out;
     -webkit-transition:box-shadow .4s ease-out;
     -o-transition:box-shadow .4s ease-out;
}

@media only screen and (max-width:959px) {
     .product-listing {
         width:98% !important;
    }
}
@media only screen and (max-width:767px) {
     .toolbar-section p, .toolbar-section a, .toolbar-section strong, .toolbar-section label, .toolbar-section li, .toolbar-section .per-page{
         font-size:12px;
    }
     .toolbar-section select {
         height:26px;
         padding:3px;
    }
     .pager .pages li {
         height:25px;
         line-height:25px;
    }
     .products-grid .item button.button span span {
         padding:0px !important;
         height:40px !important;
         line-height:40px;
    }
     .pager .pages li {
         display:inherit;
    }
}
@media only screen and (max-width:767px) {
     .quickviewbutton {
         display:none;
    }
}
.item .quickviewbutton {
     left:50%;
     transform:translateX(-50%);
}


#quick-view-modal .modal-footer {
     border-top:none;
}

#quick-view-modal button.button span span {
     max-width:100px;
     padding:0 40px;
}
.quick-view-modal-dialog .product-view .btn-cart span span {
     padding:0 20px !important;
}
.quick-view-modal-dialog .button.pull-right {
     color:#2A2A6C;
}
.module-product-details__qtyInput .qty-wrapper {
     display:flex;
     border:1px solid #2a2a6c;
     border-radius:10px;
}
.decrement-qty, .increment-qty {
     width:40px;
     display:inline-block;
     cursor:pointer;
     font-size:16px;
     margin:auto;
     text-align:center;
}
.quickviewbutton{
     background-color:transparent!important;
}